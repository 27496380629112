import type { ReactNode, HTMLAttributes } from "react";
import { isValidElement, Children, cloneElement } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode[];
}

const ButtonGroup = ({ children, ...rest }: Props) => {
  const renderChildren = () => {
    return Children.map(children, (child, index) => {
      if (isValidElement(child)) {
        if (index === 0) {
          return cloneElement(child, {
            ...(child as JSX.Element).props,
            latch: "right",
          });
        } else if (index === children.length - 1) {
          return cloneElement(child, {
            ...(child as JSX.Element).props,
            latch: "left",
          });
        } else {
          return cloneElement(child, {
            ...(child as JSX.Element).props,
            latch: "middle",
          });
        }
      }
    });
  };

  return <div {...rest}>{renderChildren()}</div>;
};

export { ButtonGroup };
