// @ts-strict-ignore
import { faArrowDownToBracket, faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import type { HTMLAttributeAnchorTarget, HTMLProps, ReactNode } from "react";

import { FontAwesomeIcon } from "@shared/react-fontawesome";
import { NavLink, type To } from "@shared/react-router-dom";

import { DropdownItem } from "../DropdownItem";

import * as styles from "./DropdownLink.styles";

interface DropdownLinkProps extends HTMLProps<HTMLDivElement> {
  /**
   * Sets boilerplate styling for component
   *
   * @default false
   */
  noStyle?: boolean;
  /**
   * sets use of active styling associated with matching url
   *
   *
   */
  noActiveStyling?: boolean;
  /**
   * path prop, use with links within react app
   */
  to?: To;
  /**
   * path prop, use with links leaving current react app
   */
  href?: string;
  /**
   * html attribute to determine which tab an href opens in
   *
   * @default "_blank"
   */
  target?: HTMLAttributeAnchorTarget;
  /**
   * HTML standard
   */
  download?: boolean;
  /**
   * Callback fired when component selected
   */
  onSelect?: () => void;
  /**
   * Removes the width truncation text limit
   */
  noTruncation?: boolean;
  /**
   * Content to render within the link
   *
   * @default undefined
   */
  /**
   * Disable the display of the external icon
   * @default false
   */
  noExternalIcon?: boolean;
  children?: ReactNode;
}

const DropdownLink = ({
  children,
  to,
  noActiveStyling,
  noStyle,
  download,
  href,
  disabled,
  noExternalIcon,
  target = "_blank",
  ...rest
}: DropdownLinkProps) => {
  const conditionalStyles = [
    !noStyle && styles.dropdownLink,
    !noStyle && disabled && styles.disabled,
  ];

  const conditionalNavStyles = [
    !noStyle && styles.navLink(noActiveStyling),
    !noStyle && disabled && styles.disabled,
  ];

  const isExternal = () => {
    if (href) {
      return href.includes("www") || href.includes("http");
    }
  };

  const options = {
    ...(isExternal() && { target: "_blank", rel: "noreferrer noopener" }),
  };

  if (href) {
    return (
      <DropdownItem css={conditionalStyles} tabIndex={-1} noStyle {...rest}>
        <a
          css={styles.navLink(noActiveStyling)}
          tabIndex={0}
          href={href}
          target={target}
          download={download}
          {...options}
        >
          {children}
          {!noExternalIcon && isExternal() && (
            <FontAwesomeIcon
              css={styles.externalIcon}
              icon={download ? faArrowDownToBracket : faArrowUpRightFromSquare}
            />
          )}
        </a>
      </DropdownItem>
    );
  }

  return (
    <DropdownItem css={conditionalStyles} tabIndex={-1} noStyle {...rest}>
      <NavLink to={to} css={conditionalNavStyles} end download={download}>
        {children}
      </NavLink>
    </DropdownItem>
  );
};

export { DropdownLink, type DropdownLinkProps };
