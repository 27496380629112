// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { clinicRoutes } from "@clinic/shared/data/clinicRoutes";

const practitionerRoutes = {
  ...clinicRoutes,
  legacyHome: "/p/stores",
  account: "/p/stores/account",
  peerReferrals: "/o/peer_referrals",
  dashboard: "/o/dashboard",
  referral: "/p/stores/referral",
  store_welcome: "/p/welcome",
  wrong_account_type: "/p/wrong-account-type",
  stock_locations: "/w/stock_locations",
  npSync: "/w/np-sync",
  onboardingCredentialsUpload: "/o/onboarding/credentials",
  onboardingNPIConfirmation: "/o/onboarding/npi-confirmation",

  landingPagePreview: urlSlug => `/welcome/${urlSlug}?preview=true`,
  switch_accounts: "/accounts",
} as const;

export { practitionerRoutes };
